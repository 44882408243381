<template>
  <div class="d-flex justify-content-start align-items-start">
    <img
      v-if="product && product.image && product.image.src"
      :src="product.image.src"
      style="width: 90px; object-fit: cover;"
    >
    <div
      class="ml-2"
      style="width: 100%;"
    >
      <div class="d-flex align-items-center justify-content-between w-full">
        <div class="h6 font-weight-bolder mb-0">
          {{ product.title }}
        </div>
        <feather-icon
          icon="XIcon"
          size="20"
          class="text-secondary cursor-pointer"
          @click="remove(product.variationId)"
        />
      </div>
      <div
        class="text-secondary text-lighten-2"
        style="margin-top: 4px;"
      >
        <span v-if="product.quantity && !product.notToUse">x{{ product.quantity }}&nbsp;/&nbsp;</span>
        <span v-if="product.size">{{ product.size }}&nbsp;/&nbsp;</span>
        <span v-if="product.lattes"><strong>Lattes:</strong> {{ product.lattes }}&nbsp;/&nbsp;</span>
        <span v-if="product.color">{{ renderColor(product.color) }}&nbsp;/&nbsp;</span>
        <span v-if="product['tete-de-lit']"><strong>Tête:</strong> {{ product['tete-de-lit'] }}&nbsp;</span>
        <div style="margin-top: 4px;">
          <b-badge
            v-if='!product.notToUse'
            pill
            variant="success"
          >
            {{ product.price * product.quantity }}€
          </b-badge>
          <b-badge
            v-if="product.meta_data && product.meta_data.find(m => m.key === '_isTakeAway') && product.meta_data.find(m => m.key === '_isTakeAway').value"
            class="ml-1"
            pill
            variant="warning"
          >
            Pris en boutique
          </b-badge>
          <b-badge
            v-if="product.meta_data && product.meta_data.find(m => m.key === '_reprise_ancien') && product.meta_data.find(m => m.key === '_reprise_ancien').value"
            class="ml-1"
            pill
            variant="warning"
          >
            Reprise ancien
          </b-badge>
          <b-badge
            v-if="product.meta_data && product.meta_data.find(m => m.key === '_montage') && product.meta_data.find(m => m.key === '_montage').value"
            class="ml-1"
            pill
            variant="warning"
          >
            Montage
          </b-badge>
          <b-badge
            v-if="product.meta_data && product.meta_data.find(m => m.key === '_montage_lit_coffre') && product.meta_data.find(m => m.key === '_montage_lit_coffre').value"
            class="ml-1"
            pill
            variant="warning"
          >
            Montage
          </b-badge>
          <ul v-if="product.type === 'woosb'">
            <li v-for="(woosb, key) of product.woosb" :key="key">
              <span class="text-base">{{ woosb.name }}</span>
              <br>
              <span v-if="woosb.taille" class="font-action text-sm">
                <strong><u>Taille:</u></strong> {{ woosb.taille }}
                <br>
              </span>
              <span v-if="woosb.Option" class="font-action text-sm">
                <strong><u>Option:</u></strong> {{ woosb.Option }}
                <br>
              </span>
              <span v-if="woosb.color" class="font-action text-sm">
                <strong><u>Couleur:</u></strong> {{ woosb.color }}
                <br>
              </span>
              <span v-if="woosb.couleur" class="font-action text-sm">
                <strong><u>Couleur:</u></strong> {{ woosb.couleur }}
                <br>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import products from '@/utils/products'

export default {
  components: { BBadge },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions('cart', ['remove']),
    renderColor(e) {
      return products.colorName(e)
    },
  },
}
</script>
