const colorName = hexa => {
  let color
  switch (hexa) {
    case '6c3521':
      color = 'Marron'
      break
    case 'ebd7c2':
      color = 'Beige'
      break
    case '2a2c41':
      color = 'Bleu nuit'
      break
    case '637369':
      color = 'Vert olive'
      break
    case 'b1c6c1':
      color = 'Bleu vert'
      break
    case 'dcdad9':
      color = 'Gris perle'
      break
    case 'e8d2d5':
      color = 'Rose poudré'
      break
    case 'f8f8f2':
      color = 'Ivoire'
      break
    default:
      color = hexa
  }
  return color
}

const selectName = value => {
  let name
  switch (value.toLowerCase()) {
    case 'taille':
      name = 'Taille'
      break
    case 'couleur':
      name = 'Couleur'
      break
    case 'lattes':
      name = 'Type de lattes'
      break
    case 'tete-de-lit':
      name = 'Tête de lit'
      break
    case 'pieds':
      name = 'Pieds'
      break
    case 'tablettes-duo':
      name = 'Tablettes duo'
      break
    default:
      name = value
  }
  return name
}

const formattedOrder = (form, shipping, billing, carts, otherBilling, discount) => {
  const data = {
    ...form,
    shipping: {
      ...form.shipping, ...shipping,
    },
    billing: otherBilling ? { ...form.billing, ...billing } : { ...form.shipping, ...shipping },
    line_items: carts.map(({
      quantity,
      type,
      productId,
      variationId,
      meta_data,
      color,
      size,
      priceCadeau,
      woosb,
      notToUse,
      price,
      categories,
      isOffer,
    }) => ({
      product_id: productId, variation_id: variationId, quantity, meta_data, type, color, size, priceCadeau, woosb, notToUse, price, categories, isOffer,
    })),
  }
  if (discount?.length) {
    data.coupon_lines = discount.map(d => ({ code: d.name }))
  }
  return data
}

export default {
  selectName,
  colorName,
  formattedOrder,
}
