<template>
  <div class="pb-12 pt-14 px-12 bg-white w-max mx-auto shadow-xl mb-6">
    <div class="max-w-full">
      <div id="myPaymentForm" />
    </div>
    <div>{{ promiseError }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import KRGlue from '@lyracom/embedded-form-glue'

export default {
  props: {
    formToken: {
      type: String,
      required: true,
    },
    number: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      promiseError: '',
    }
  },
  computed: {
    ...mapGetters('order', ['order']),
  },
  mounted() {
    const endpoint = 'https://static.payzen.eu'
    const publicKey = '36301096:publickey_PxQCZ2EtgYg0uCOL8XuJv60HcVApstBx8dMdWqFvCLsuX'
    const { formToken } = this
    this.init(endpoint, publicKey, formToken)
  },
  methods: {
    async init(endpoint, publicKey, formToken) {
      KRGlue.loadLibrary(endpoint, publicKey)
        .then(({ KR }) => KR.setFormConfig({ formToken, 'kr-language': 'fr-FR' }))
        .then(({ KR }) => KR.addForm('#myPaymentForm')) /* create a payment form */
        .then(({ KR, result }) => {
          KR.showForm(result.formId)
            .then(({ KR }) => {
              this.$emit('form-create')
              KR.onSubmit(this.confirm)
            })
        }) /* show the payment form */
        .catch(error => {
          this.promiseError = `${error} (see console for more details)`
          return true
        })
    },
    async confirm({ clientAnswer }) {
      const paymentMethodToken = clientAnswer.transactions.find(transac => transac.paymentMethodToken)?.paymentMethodToken
      if (paymentMethodToken) {
        this.$emit('confirm', { paymentMethodToken, number: this.number, payment_method: 'payzenmulti' })
      }
    },
  },
}
</script>

<style lang="scss">
@import 'bootstrap/scss/functions';
@import '~@core/scss/base/bootstrap-extended/variables';
@import 'bootstrap/scss/variables';
@import '~@core/scss/base/components/variables-dark';

#myPaymentForm {
  margin: auto;
  max-width: 50rem;
  .kr-payment-button {
    cursor: pointer;
    border: 0;
    transition: 0.2s;
    color: white;
    font-weight: 500;
    padding: 8px 24px !important;
    text-transform: inherit !important;
    border-radius: 6px !important;
    background-color: $primary;
    margin-top: 6px;
    &:hover {
      background-color: darken($primary, 10%) !important;
    }
    &[disabled] {
      cursor: not-allowed !important;
      opacity: .5;
      &:hover {
        background-color: $primary !important;
      }
    }
  }
  .kr-pan, .kr-expiry, .kr-security-code, .kr-card-holder-mail {
    border: 1px solid lighten($secondary, 30%);
    padding: 8px 16px !important;
    margin-bottom: 38px !important;
    height: 42px;
    padding-top: 9px;
    &.kr-error {
      border-color: $danger !important;
    }
    &::before {
      content: 'Numéro de carte';
      color: $secondary;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
      top: -24px;
      left: 0;
      position: absolute;
    }
  }
  .kr-expiry {
    &::before { content: 'Date d\'expiration' }
  }
  .kr-security-code {
    &::before { content: 'CVV' }
  }
  .kr-card-holder-mail {
    margin-bottom: 20px !important;
    &::before { content: 'E-MAIL' }
  }
}
</style>
