<template>
  <div class="navbar-light header-navbar rounded-lg shadow-lg p-2">
    <h4 v-if="active === 2" class="item-name mb-2">
      Récapitulatif
    </h4>
    <h4 v-else class="item-name mb-2">
      Mon panier ({{ total }})
    </h4>
    <div v-if="active === 2" class="mb-2">
      <p class="card-text mb-25">
        <strong><u>Note de commande:</u></strong>
      </p>
      <p class="card-text mb-25">
        {{ order.form.customer_note }}
      </p>
      <p class="card-text mb-25">
        <strong><u>Livraison</u></strong>
      </p>
      <p class="card-text mb-25">
        {{ order.form.shipping.first_name }} {{ order.form.shipping.last_name }}
      </p>
      <p class="card-text mb-25">
        {{ order.form.shipping.email }}
      </p>
      <p class="card-text mb-25">
        {{ order.form.shipping.phone }}
      </p>
      <p class="card-text mb-25">
        {{ order.form.shipping.address_1 }}
      </p>
      <p
        v-if="order.form.shipping.addres_2"
        class="card-text mb-25"
      >
        {{ order.form.shipping.addres_2 }}
      </p>
      <p
        v-if="order.form.shipping.company"
        class="card-text mb-25"
      >
        {{ order.form.shipping.company }}
      </p>
      <p class="card-text mb-25">
        {{ order.form.shipping.postcode }} {{ order.form.shipping.city }}, {{ order.form.shipping.state }} {{ order.form.shipping.country }}
      </p>
      <p class="card-text mb-25">
        <strong><u>Facturation</u></strong>
      </p>
      <p class="card-text mb-25">
        {{ order.form.billing.first_name || order.form.shipping.first_name }} {{ order.form.billing.last_name || order.form.shipping.last_name }}
      </p>
      <p class="card-text mb-25">
        {{ order.form.billing.address_1 || order.form.shipping.address_1 }}
      </p>
      <p
        v-if="order.form.billing.addres_2 || order.form.shipping.addres_2"
        class="card-text mb-25"
      >
        {{ order.form.billing.addres_2 }}
      </p>
      <p
        v-if="order.form.billing.company || order.form.shipping.company"
        class="card-text mb-25"
      >
        {{ order.form.billing.company || order.form.shipping.company }}
      </p>
      <p class="card-text mb-25">
        {{ order.form.billing.postcode || order.form.shipping.postcode }} {{ order.form.billing.city || order.form.shipping.city }}, {{ order.form.billing.state || order.form.shipping.state }} {{ order.form.billing.country || order.form.shipping.country }}
      </p>
    </div>
    <template v-if="items.length && items.length > 0">
      <Product
        v-for="(product, p) in items"
        :key="p"
        :product="product"
        class="mt-1"
      />
    </template>
    <p
      v-else
      class="text-secondary mt-1"
    >
      Votre panier est vide :(
    </p>
    <div class="text-center mt-3">
      <div
        v-if="discount && discount.length"
        class="h6 font-weight-bolder mb-2"
      >
        Code promo &nbsp;:
        <span
          v-for="(d, key) of discount"
          :key="key"
        >
          {{ d.name }} ({{ d.amount }}€)
        </span>
        <feather-icon
          style="cursor: pointer;"
          icon="XIcon"
          class="text-body"
          @click="resetDiscount()"
        />
      </div>
      <div
        v-if="order.shipping.country === 'CH' || order.shipping.country === 'Suisse'"
        class="h5 font-weight-bolder"
      >
        TVA: 0%
        <br>
        Frais de livraison: {{ shippingPrice }}€
      </div>
      <div
        v-if="order.shipping.country === 'BE' || order.shipping.country === 'Belgique'"
        class="h5 font-weight-bolder"
      >
        TVA: 21%
      </div>
      <div class="h5 font-weight-bolder">
        Total à régler&nbsp;: {{ getPrice() }} €
      </div>
      <b-button
        v-if="active === 0"
        :disabled="items.length === 0"
        variant="primary"
        class="btn-cart mt-1"
        style="width: 100%;"
        @click="$emit('next')"
      >
        <feather-icon
          icon="CreditCardIcon"
          class="mr-50"
        />
        <span>Passer en caisse</span>
      </b-button>
      <b-button
        variant="secondary"
        class="btn-cart mt-1"
        style="width: 100%;"
        @click="resetOrder()"
      >
        <feather-icon
          icon="RefreshCwIcon"
          class="mr-50"
        />
        <span>Redémarrer à zéro</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { BButton } from 'bootstrap-vue'
import Product from '@/components/encaissement/Cart/Product.vue'

export default {
  components: {
    Product,
    BButton,
  },
  props: {
    active: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('order', ['order']),
    ...mapGetters('cart', ['items', 'total', 'price', 'discount']),
  },
  data() {
    return {
      shippingPrice: null,
    }
  },
  methods: {
    ...mapActions('cart', ['resetDiscount', 'reset']),
    ...mapActions('order', ['remove']),
    getPrice() {
      // eslint-disable-next-line prefer-destructuring
      let price = this.price
      this.shippingPrice = 0
      if (this?.order.shipping.country === 'BE' || this?.order.shipping.country === 'Belgique') {
        price = ((Number(this.price) / 1.2) * 1.21)
      }
      if (this?.order.shipping.country === 'CH' || this?.order.shipping.country === 'Suisse') {
        price = (Number(this.price) / 1.2)
        // eslint-disable-next-line no-restricted-syntax
        for (const item of this.items) {
          if (item?.categories.includes('matelas')) {
            price += 80 * item.quantity
            this.shippingPrice += 80 * item.quantity
          } else if (item?.categories.includes('sommier')) {
            price += 190 * item.quantity
            this.shippingPrice += 190 * item.quantity
          } else if (item?.categories?.includes('oreiller') || item?.categories?.includes('le-linge-de-lit')) {
            price += 20 * item.quantity
            this.shippingPrice += 20 * item.quantity
          } else {
            price += 50 * item.quantity
            this.shippingPrice += 50 * item.quantity
          }
        }
      }
      return (price).toFixed(2)
    },
    resetOrder() {
      this.resetDiscount()
      this.reset()
      this.remove()
    },
  },
}
</script>
