<template>
  <div class="navbar-light header-navbar rounded-lg shadow-lg p-2">
    <Form
      v-if="order"
      :loading="loading"
      :data="order"
      :countries="countries"
      @submit="submit"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Form from '@/components/encaissement/forms/Address.vue'

export default {
  components: {
    Form,
  },
  props: {
    countries: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: ['FR', 'CH', 'BE', 'LU'],
    },
  },
  data() {
    return {
      loading: false,
      order: null,
    }
  },
  async created() {
    this.order = await this.get()
  },
  methods: {
    ...mapActions('order', ['get', 'set']),
    async submit(data) {
      this.set(data)
      this.$emit('next')
    },
  },
}
</script>
