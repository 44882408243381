<template>
  <div class="navbar-light header-navbar rounded-lg shadow-lg p-3 text-center">
    <div class="h3">
      Commande validée&nbsp;!
    </div>

    <p>Un mail de confirmation de commande va être envoyé sur la boîte de mail de notre client.</p>
    <p>Le numéro de la commande est: <strong v-if="order">{{ order.number }}</strong></p>
    <b-button
      variant="primary"
      class="mt-1"
      @click="back"
    >
      <span>Revenir à la prise de commande</span>
    </b-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { BButton } from 'bootstrap-vue'

export default {
  components: { BButton },
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  methods: {
    ...mapActions('cart', ['reset']),
    ...mapActions('order', ['remove']),
    back() {
      this.reset()
      this.remove()
      this.$emit('reset')
    },
  },
}
</script>
