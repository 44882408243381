<template>
  <div
    v-if="order"
    class="navbar-light header-navbar rounded-lg shadow-lg p-2"
  >
    <b-modal
      v-model="openModal"
      ok-only
      ok-title="Accept"
      centered
      size="md"
      :hide-footer="true"
      title="Devis client"
    >
      <div>
        <b-form-textarea
          v-model="noteDevis"
          placeholder="Note pour le client"
        />
        <b-button
          :disabled="loading"
          variant="primary"
          class="btn-cart mt-1"
          style="width: 100%;"
          @click="share()"
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-50"
          />
          <span>Télécharger</span>
        </b-button>
      </div>
      <!-- <div>
        <p>Le mail vient d'être envoyé au client avec les produits et le lien correspondant sur le site.</p>
        <a :href="`https://kipli.com/fr/cart?id=${link}`">{{ `https://kipli.com/fr/cart?id=${link}` }}</a>
      </div> -->
    </b-modal>
    <div id="alma-widget-modal" class="alma-widget-modal" />
    <div class="h4 mb-3">
      Coupon de réduction
    </div>
    <b-row class="d-flex align-items-center mb-2">
      <b-col
        md="6"
      >
        <b-form-input
          v-model="coupon"
          class="d-inline-block mr-1"
          placeholder="Code promo...."
          @keyup.native.enter="checkCoupon"
        />
      </b-col>
      <b-col
        md="6"
      >
        <b-button
          v-if="coupon && coupon !== '' && (!discount || discount.length === 0)"
          :disabled="loading"
          variant="primary"
          @click="checkCoupon"
        >
          Vérifier le coupon
        </b-button>
      </b-col>
      <b-col
        v-if="amount > 0"
        md="12"
        class="mt-2"
      >
        <span>Ce coupon de réduction vous permet d'économiser <strong>{{ amount }}€</strong></span>
      </b-col>
    </b-row>
    <div v-if="typePayment !== 'SAV'" class="h4 mb-3">
      Méthode de paiement
    </div>
    <b-row v-if="typePayment !== 'SAV'" id="formPayment">
      <b-col
        v-for="(method, m) in getMethods()"
        :key="m"
        md="4"
        class="mb-2"
      >
        <div
          :class="{ 'border-primary': m === active, 'border-secondary border-lighten-5': m !== active }"
          class="border border-primary rounded-lg p-2 cursor-pointer d-flex align-items-center"
          :style="`border-width: 2px !important;${m !== active ? 'opacity: 0.8' : ''}`"
          @click="active = m"
        >
          <img
            :src="require(`@/assets/images/payments/${method.image}.svg`)"
            style="height: 30px;"
            alt=""
          >
          <div class="h5 font-weight-bolder mb-0 ml-1">
            {{ method.payment_method_title }}
          </div>
        </div>
      </b-col>
    </b-row>
    <div v-if="['alma-10', 'alma-3'].includes(methods[active].payment_method)">
      <p>Pour voir le détail des écheances, cliquer <span @click="openModalAlma" class="text-warning">ici</span></p>
    </div>
    <div v-if="['alma-10', 'alma-3'].includes(methods[active].payment_method) && link">
      <p>Si le client ne recoit pas le SMS, voici le lien de paiement à lui partager: <span class="text-warning">{{ link }}</span></p>

      <b-button
        variant="primary"
        class="mt-1 mb-4"
        @click="$emit('next')"
      >
        <span>Le paiement est effectué</span>
      </b-button>
    </div>
    <table
      v-if="methods[active].payment_method === 'payzenmulti'"
      class="table"
    >
      <thead>
        <tr>
          <th scope="col">
            1ère écheance ({{ $moment().format('DD-MM-YYYY') }})
          </th>
          <th scope="col">
            2ème écheance ({{ $moment().add(1, 'months').format('DD-MM-YYYY') }})
          </th>
          <th scope="col">
            3ème écheance ({{ $moment().add(2, 'months').format('DD-MM-YYYY') }})
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ (price / 3).toFixed(2) }}€</td>
          <td>{{ (price / 3).toFixed(2) }}€</td>
          <td>{{ (price / 3).toFixed(2) }}€</td>
        </tr>
      </tbody>
    </table>
    <template v-if="payment && payment.answer && payment.answer.formToken && methods[active].payment_method === 'payzenmulti'">
      <div class="h4 mb-3">
        Carte de crédit
      </div>
      <Payment
        :number="order.number"
        :form-token="payment.answer.formToken"
        @confirm="confirm"
      />
    </template>
    <b-row v-if="!link">
      <b-col class="col-4">
        <b-button
          :disabled="loading"
          variant="secondary"
          class="btn-cart"
          style="width: 100%;"
          @click="openModal = true"
        >
          <span>Générer un devis</span>
        </b-button>
      </b-col>
      <b-col class="col-6">
        <b-button
          v-if="methods[active].payment_method"
          :disabled="loading"
          variant="primary"
          @click="checkout"
        >
          Créer ma commande
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BModal,
  BFormTextarea,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import Payment from '@/components/payment/payzen/index.vue'
import products from '@/utils/products'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    Payment,
    BFormInput,
    BModal,
    BFormTextarea,
  },
  props: {
    country: {
      type: String,
      default: 'FR',
    },
    typePayment: {
      type: String,
      default: 'boutique',
    },
  },
  data() {
    return {
      order: null,
      payment: null,
      loading: false,
      link: null,
      active: 0,
      amount: 0,
      coupon: '',
      openModal: false,
      noteDevis: '',
      methods: [{
        payment_method_title: 'TPE Boutique',
        payment_method: 'offline_gateway',
        image: 'credit-card',
      },
      {
        payment_method_title: 'Paiement par carte',
        payment_method: 'thunes',
        image: 'credit-card',
      }, {
        payment_method_title: 'Espèces',
        payment_method: 'espece',
        image: 'espece',
      }, {
        payment_method_title: 'Chèques',
        payment_method: 'cheque',
        image: 'cheque',
      }, {
        payment_method_title: 'Paiement en 3 fois',
        payment_method: 'alma-3',
        image: 'alma',
      }, {
        payment_method_title: 'Virement bancaire',
        payment_method: 'sepa',
        image: 'sepa',
        type: 'SAV',
      }, {
        payment_method_title: 'Paiement en 10 fois',
        payment_method: 'alma-10',
        image: 'alma',
      },
      {
        payment_method_title: 'Payer en 3x avec Payzen',
        payment_method: 'payzenmulti',
        image: 'multipayment',
      },
      {
        payment_method_title: 'Commande à valider en interne',
        payment_method: 'internalorder',
        image: 'credit-card',
      }],
    }
  },
  computed: {
    ...mapGetters('cart', ['items', 'discount', 'price']),
  },
  async created() {
    const order = await this.get()
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/@alma/widgets@2.x/dist/widgets.umd.js')
    document.head.appendChild(recaptchaScript)
    if (order?.form?.shipping?.first_name) {
      this.order = order
    } else {
      this.$emit('reset')
    }
  },
  methods: {
    ...mapActions('order', ['get', 'remove']),
    ...mapActions('cart', ['addDiscount', 'reset']),
    back() {
      this.reset()
      this.remove()
      this.$emit('reset')
    },
    getMethods() {
      if (this.typePayment === 'SAV') {
        return this.methods.filter(m => m.type === 'SAV')
      }
      return this.methods
    },
    openModalAlma() {
      const widgets = window.Alma.Widgets.initialize(
        '11uSlsk4MGyi5yseJJcdmm6FDaKyJ4pT6N', // ID marchand
        window.Alma.ApiMode.LIVE, // mode de l'API (LIVE ou TEST)
      )
      this.modalAlma = widgets.add(window.Alma.Widgets.Modal, {
        container: '#alma-widget-modal',
        clickableSelector: '#alma-open-modal-button',
        purchaseAmount: Number(this.price) * 100,
        locale: 'fr',
        plans: [
          {
            installmentsCount: 3,
            minAmount: 5000,
            maxAmount: 400000,
          },
          {
            installmentsCount: 10,
            minAmount: 5000,
            maxAmount: 400000,
          },
        ],
      })
      this.modalAlma.open()
    },
    async checkCoupon() {
      try {
        if (this.discount?.find(d => d.name?.toLowerCase() === this.coupon?.toLowerCase())) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Vous ne pouvez pas utiliser deux fois le même coupon.',
              icon: 'EditIcon',
              variant: 'error',
            },
          })
        } else if (this.discount.filter(d => d.individualUse === true)?.length) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Le coupon déjà présent ne peut pas être utilisé avec un autre.',
              icon: 'EditIcon',
              variant: 'error',
            },
          })
        } else {
          this.loading = true
          const { amount, individualUse } = await this.$http.post(`/admin/coupons/${this.coupon}?country=${this.country}`, { items: this.items })
          if (this.discount?.length > 0 && individualUse) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Ce coupon ne peut pas être utilisé avec un autre.',
                icon: 'EditIcon',
                variant: 'error',
              },
            })
          } else {
            this.amount = amount
            await this.addDiscount({ individualUse, amount, name: this.coupon })
          }
          this.loading = false
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async confirm({ number, payment_method, paymentMethodToken }) {
      try {
        this.loading = true
        const { success } = await this.$http.post(`/admin/order/${number}/confirm`, { payment_method, paymentMethodToken })
        if (success) {
          this.$emit('next')
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async checkout() {
      const formattedOrder = products.formattedOrder(this.order.form, this.order.shipping, this.order.billing, this.items, this.order.hasOtherBilling, this.discount)
      this.loading = true
      try {
        const { order } = await this.$http.post(`/admin/order?country=${this.country}`, formattedOrder)
        if (order.number && this.typePayment === 'SAV') {
          this.$emit('next', order)
        } else if (order.number) {
          this.order = order
          const { payment_method, payment_method_title } = this.methods[this.active]
          if (order?.number && payment_method_title && payment_method) {
            const { data, success, link } = await this.$http.post(`/admin/order/${order.number}/checkout`, { payment_method, payment_method_title })
            if (data?.answer) {
              this.payment = data
            } else if (link) {
              this.link = link
            } else if (success) {
              this.$emit('next', this.order)
            } else if (payment_method === 'payzenmulti') {
              const form = document.createElement('form')
              form.setAttribute('method', 'POST')
              form.setAttribute('action', 'https://secure.payzen.eu/vads-payment/')
              // eslint-disable-next-line no-restricted-syntax
              for (const attribute of Object.entries(data)) {
                const input = document.createElement('input')
                input.setAttribute('type', 'hidden')
                input.setAttribute('name', attribute[0])
                input.setAttribute('value', attribute[1])
                form.appendChild(input)
              }
              const input = document.createElement('input')
              input.setAttribute('type', 'submit')
              input.setAttribute('name', 'Payer')
              input.setAttribute('value', 'Payer')
              input.setAttribute('class', 'button primary')
              input.setAttribute('style', 'display: none;')
              form.appendChild(input)

              document.getElementById('formPayment').appendChild(form)
              input.click()
            }
          }
        }
      } finally {
        this.loading = false
      }
    },
    async share() {
      try {
        this.loading = true
        // eslint-disable-next-line prefer-destructuring
        let price = this.price
        let shippingPrice = 0
        if (this?.order?.form.shipping.country === 'BE' || this?.order?.form.shipping.country === 'Belgique') {
          price = ((Number(this.price) / 1.2) * 1.21)
        }
        if (this?.order?.form.shipping.country === 'CH' || this?.order?.form.shipping.country === 'Suisse') {
          price = (Number(this.price) / 1.2)
          // eslint-disable-next-line no-restricted-syntax
          for (const item of this.items) {
            if (item?.categories.includes('matelas')) {
              price += 80 * item.quantity
              shippingPrice += 80 * item.quantity
            } else if (item?.categories.includes('sommier')) {
              price += 190 * item.quantity
              shippingPrice += 190 * item.quantity
            } else if (item?.categories?.includes('oreiller') || item?.categories?.includes('le-linge-de-lit')) {
              price += 20 * item.quantity
              shippingPrice += 20 * item.quantity
            } else {
              price += 50 * item.quantity
              shippingPrice += 50 * item.quantity
            }
          }
        }
        const { link } = await this.$http.post('/admin/devis',
          {
            order: {
              ...this.order, shipping: this.order.form.shipping, line_items: this.items, total: this.total, price, discount: this.discount, shippingPrice, paymentMethod: this.methods[this.active].payment_method_title, noteDevis: this.noteDevis,
            },
          })
        window.open(link)
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style>
@import 'https://cdn.jsdelivr.net/npm/@alma/widgets@2.x/dist/widgets.min.css';
</style>
