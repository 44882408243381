<template>
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent="submit">
      <b-row class="mb-4">
        <b-col md="6">
          <v-select
            v-model="shippingBoutiqueSelect"
            placeholder="Pré-remplir avec adresse boutique"
            label="boutique"
            :options="shippingBoutique"
          />
        </b-col>
        <b-col md="6">
          <b-button @click="shippingInfo()">
            OK
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group label="Nom">
            <validation-provider
              name="Nom"
              rules="required"
            >
              <b-form-input
                v-model="data.form.shipping.first_name"
                placeholder="Nom"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Prénom">
            <validation-provider
              name="Prénom"
              rules="required"
            >
              <b-form-input
                v-model="data.form.shipping.last_name"
                placeholder="Prénom"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group>
            <b-form-checkbox
              v-model="data.hasCompany"
              name="checkbox-2"
              @change="resetCompany"
            >
              Ajouter un nom d'entreprise
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <template v-if="data.hasCompany">
          <b-col md="6">
            <b-form-group label="Société">
              <b-form-input
                v-model="data.form.shipping.company"
                placeholder="Société"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="TVA">
              <b-form-input
                v-model="data.form.shipping.tva"
                placeholder="TVA"
              />
            </b-form-group>
          </b-col>
        </template>
        <b-col md="6">
          <b-form-group
            v-if="google && countries"
            label="Adresse"
          >
            <!-- <vue-google-autocomplete
              v-if="countries === ['FR', 'CH', 'BE', 'LU']"
              id="map2"
              classname="form-control"
              placeholder="Adresse..."
              :country="countries.map(c => c.toLowerCase())"
              @placechanged="(e, a, b) => getAddressData(e, a, b, 'shipping')"
            /> -->
            <b-form-input v-model="data.form['shipping'].address_1" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <validation-provider
            name="Code postal"
            rules="required"
          >
            <b-form-group label="Code postal">
              <b-form-input
                v-model="data.form.shipping.postcode"
                placeholder="Code postal"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            name="Ville"
            rules="required"
          >
            <b-form-group label="Ville">
              <b-form-input
                v-model="data.form.shipping.city"
                placeholder="Ville"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            name="Région"
            rules="required"
          >
            <b-form-group label="Région">
              <b-form-input
                v-model="data.form.shipping.state"
                placeholder="Région"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            name="Pays"
            rules="required"
          >
            <b-form-group label="Pays">
              <!-- <b-form-input
                v-model="data.form.shipping.country"
                placeholder="Pays"
              /> -->
              <v-select
                v-model="data.form.shipping.country"
                :dir="'ltr'"
                label="Pays"
                :options="countries"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="12">
          <b-form-group>
            <b-form-checkbox
              v-model="data.hasOtherAddress"
              name="checkbox-2"
              @change="resetOtherAddress"
            >
              Complément d'adresse
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <template v-if="data.hasOtherAddress">
          <b-col md="12">
            <b-form-group label="Complément d'adresse">
              <b-form-input
                v-model="data.form.shipping.address_2"
                placeholder="Complément d'adresse"
              />
            </b-form-group>
          </b-col>
        </template>
        <b-col md="6">
          <validation-provider
            name="Email"
            rules="required"
          >
            <b-form-group label="Email">
              <b-form-input
                v-model="data.form.shipping.email"
                placeholder="Email"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            name="Téléphone"
            rules="required"
          >
            <b-form-group label="Téléphone">
              <vue-tel-input
                v-model="data.form.shipping.phone"
                class="kipli__phone"
                @input="setPhone"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            name="Etage"
            rules="required"
          >
            <b-form-group label="Étage">
              <v-select
                v-model="data.form.shipping.etage"
                :dir="'ltr'"
                label="Étage"
                :options="etages"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            name="Ascenceur"
            rules="required"
          >
            <b-form-group label="Ascenceur">
              <v-select
                v-model="data.form.shipping.ascenseur"
                :dir="'ltr'"

                :options="ascenseur"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Note"
            rules="required"
          >
            <v-select v-model="customerNoteSelect" placeholder="Note (Obligatoire)" :options="customerNoteOptions" @input="e => changeCustomerNote(e)"/>
            <b-form-input
              v-if="customerNoteSelect === 'Autres'"
              v-model="data.form.customer_note"
              placeholder="Note (Obligatoire)"
              name="checkbox-2"
              class="mt-1"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group>
            <b-form-checkbox
              v-model="data.form.shippingDate"
              name="checkbox-2"
            >
              Livraison après 4 semaines ?
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col v-if="data.form.shippingDate" md="12">
          <b-form-group>
            <flat-pickr
              v-model="data.form.dateSelect"
              placeholder="Date"
              class="form-control mr-1"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group>
            <b-form-checkbox
              v-model="data.hasOtherBilling"
              name="checkbox-2"
              @change="resetOtherBilling"
            >
              Facturer à une autre adresse
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <template v-if="data.hasOtherBilling">
          <b-col md="6">
            <b-form-group label="Nom">
              <b-form-input
                v-model="data.form.billing.first_name"
                placeholder="Nom"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Prénom">
              <b-form-input
                v-model="data.form.billing.last_name"
                placeholder="Prénom"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              v-if="google"
              label="Adresse"
            >
              <!-- <vue-google-autocomplete
                v-if="countries === ['FR', 'CH', 'BE', 'LU']"
                id="map3"
                classname="form-control"
                placeholder="Adresse..."
                :country="['fr', 'be', 'lu', 'ch']"
                @placechanged="(e, a, b) => getAddressData(e, a, b, 'billing')"
              /> -->
              <b-form-input v-model="data.form['billing'].address_1" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Code Postal">
              <b-form-input
                v-model="data.form.billing.postcode"
                placeholder="Code Postal"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Région">
              <b-form-input
                v-model="data.form.billing.state"
                placeholder="Code Postal"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Ville">
              <b-form-input
                v-model="data.form.billing.city"
                placeholder="Ville"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Pays">
              <b-form-input
                v-model="data.form.billing.country"
                placeholder="Pays"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <b-form-checkbox
                v-model="data.hasBillingOtherAddress"
                name="checkbox-2"
                @change="resetOtherBillingAddress"
              >
                Complément d'adresse
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <template v-if="data.hasBillingOtherAddress">
            <b-col md="12">
              <b-form-group label="Complément d'adresse">
                <b-form-input
                  v-model="data.form.billing.address_2"
                  placeholder="Complément d'adresse"
                />
              </b-form-group>
            </b-col>
          </template>
          <b-col md="6">
            <b-form-group label="Email">
              <b-form-input
                v-model="data.form.billing.email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Téléphone">
              <vue-tel-input
                v-model="data.form.billing.phone"
                class="kipli__phone"
                @input="(e, b, a) => setPhone(e, b, a, 'billing')"
              />
            </b-form-group>
          </b-col>
        </template>
        <b-col md="12">
          <b-form-group>
            <b-form-checkbox
              v-model="data.form.mailchimp_woocommerce_newsletter"
              name="checkbox-2"
            >
              Abonnez-vous à notre newsletter
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col>
          <b-button
            :disabled="loading"
            type="submit"
            variant="primary"
            class="mt-2"
          >
            Créer ma commande
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {
  BRow, BCol, BForm, BButton, BFormInput, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    BButton,
    BFormInput,
    BFormGroup,
    vSelect,
    flatPickr,
  },
  props: {
    countries: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      google: true,
      knows: ['Publicité internet', 'Facebook', 'Moteur de recherche'],
      etages: ['Rez-de-chaussée', '1ème étage', '2ème étage', '3ème étage', '4ème étage', '5ème étage', '6ème étage', '7ème étage', '8ème étage', '9ème étage', '10ème étage', '11ème étage', '12ème étage'],
      ascenseur: ['Oui', 'Non'],
      shippingBoutiqueSelect: null,
      customerNoteSelect: null,
      initCustomerNote: 0,
      customerNoteOptions: ['PARIS', 'PARIS-REMIS', 'PARIS-SAV', 'PARIS-VISIO', 'PARIS-TELEPHONE-BOUTIQUE', 'PARIS-STOCK', 'LYON', 'LYON-REMIS', 'LYON-SAV', 'LYON-TELEPHONE-BOUTIQUE', 'LYON-VISIO', 'LYON-STOCK', 'TOULOUSE', 'TOULOUSE-REMIS', 'TOULOUSE-SAV', 'TOULOUSE-VISIO', 'TOULOUSE-TELEPHONE-BOUTIQUE', 'TOULOUSE-STOCK', 'B2B', 'Autres'],
      shippingBoutique: [
        {
          boutique: 'RESET',
        },
        {
          boutique: 'PARIS',
          value: {
            first_name: 'BOUTIQUE',
            last_name: 'PARIS',
            address_1: '16 Rue de Turbigo',
            address_2: null,
            city: 'PARIS',
            state: 'IDF',
            postcode: '75002',
            country: 'FR',
            email: 'boutique@kipli.com',
            phone: '+33981099394',
            phone_code: '+33',
            company: 'KIPLI',
            tva: null,
            refer_to: null,
            etage: 'Rez-de-chaussée',
            ascenseur: 'Non',
          },
        },
        {
          boutique: 'LYON',
          value: {
            first_name: 'BOUTIQUE',
            last_name: 'LYON',
            address_1: '25 Rue de Brest',
            address_2: null,
            city: 'LYON',
            state: 'FR',
            postcode: '69002',
            country: 'FR',
            email: 'boutique-lyon@kipli.com',
            phone: '+33762186912',
            phone_code: '+33',
            company: 'KIPLI',
            tva: null,
            refer_to: null,
            etage: 'Rez-de-chaussée',
            ascenseur: 'Non',
          },
        },
        {
          boutique: 'TOULOUSE',
          value: {
            first_name: 'BOUTIQUE',
            last_name: 'TOULOUSE',
            address_1: '35 Rue Lafayette',
            address_2: null,
            city: 'TOULOUSE',
            state: 'FR',
            postcode: '31000',
            country: 'FR',
            email: 'boutique-toulouse@kipli.com',
            phone: '+33761220602',
            phone_code: '+33',
            company: 'KIPLI',
            tva: null,
            refer_to: null,
            etage: 'Rez-de-chaussée',
            ascenseur: 'Non',
          },
        },
      ],
    }
  },
  methods: {
    resetCompany(keep) {
      if (!keep) {
        this.data.form.shipping.company = null
        this.data.form.shipping.billing = null
      }
    },
    resetOtherAddress(keep) {
      if (!keep) {
        this.data.form.shipping.address_2 = null
      }
    },
    resetOtherBillingAddress(keep) {
      if (!keep) {
        this.data.form.billing.address_2 = null
      }
    },
    resetOtherBilling(keep) {
      if (!keep) {
        this.data.form.billing = {
          first_name: null,
          last_name: null,
          address_1: null,
          address_2: null,
          city: null,
          state: null,
          postcode: null,
          country: null,
          email: null,
          phone: null,
          phone_code: null,
          company: null,
          tva: null,
          refer_to: null,
          etage: null,
          ascenseur: null,
        }
      }
    },
    resetAll() {
      this.data.form.billing = {
        first_name: null,
        last_name: null,
        address_1: null,
        address_2: null,
        city: null,
        state: null,
        postcode: null,
        country: null,
        email: null,
        phone: null,
        phone_code: null,
        company: null,
        tva: null,
        refer_to: null,
        etage: null,
        ascenseur: null,
      }
      this.data.form.shipping = {
        first_name: null,
        last_name: null,
        address_1: null,
        address_2: null,
        city: null,
        state: null,
        postcode: null,
        country: null,
        email: null,
        phone: null,
        phone_code: null,
        company: null,
        tva: null,
        refer_to: null,
        etage: null,
        ascenseur: null,
      }
    },
    getAddressData({ postal_code, locality, country }, { address_components, formatted_address, name }, id, key = 'shipping') {
      const state = address_components.find(ad => ad.types.find(t => t === 'administrative_area_level_1'))
      const formattedCountry = address_components.find(ad => ad.types.find(t => t === 'country'))
      if (formatted_address) this.data.form[key].address_1 = name
      if (postal_code) this.data.form[key].postcode = postal_code
      if (state) this.data.form[key].state = state?.short_name ?? null
      if (country || formattedCountry) {
        // this.data[key].country = formattedCountry?.short_name ?? null
        this.data.form[key].country = formattedCountry?.short_name
      }
      if (locality) this.data.form[key].city = locality
    },
    setPhone(formatted, { countryCallingCode, number }, e, key = 'shipping') {
      if (countryCallingCode) this.data.form[key].phone_code = countryCallingCode
      if (number) this.data[key].phone = number
    },
    shippingInfo() {
      if (this.shippingBoutiqueSelect) {
        if (this.shippingBoutiqueSelect?.boutique === 'RESET') {
          this.resetAll()
        } else {
          console.log(this.shippingBoutiqueSelect)
          this.data.form.shipping = this.shippingBoutiqueSelect.value
          this.data.form.billing = this.shippingBoutiqueSelect.value
        }
      }
    },
    async submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (['', null, undefined].includes(this.data.form.customer_note) || ['', null, undefined].includes(this.customerNoteSelect)) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Vous devez entrer une note de commande.',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return false
        }
        if (!this.data.form.shipping.address_1) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Vous devez entrer une adresse.',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return false
        }
        if (success) {
          this.data.shipping.country = this.data.form.shipping.country || null
          // eslint-disable-next-line
          this.$emit('submit', this.data)
          return true
        }
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Vous devez remplir tous les champs.',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return false
      }).catch(err => console.log(err))
    },
    changeCustomerNote(e) {
      this.data.form.customer_note = e
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
